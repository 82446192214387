<template>
  <div class="overlay">
    <page-title>{{i18n.MENU.my}}</page-title>
    <div class="my-door">
      <div class="model__empty" v-if="items.length === 0">
        {{i18n.MY.NO_DOORS}}
      </div>
      <div class="my-door__item"
           v-for="(item, idx) in items"
           :key="`model__${idx}`">
        <a href="#"
           class="my-door__link"
           @click.prevent="setModel(item.code)">
          <img class="my-door__img"
               :src="item.image"
               alt="">
        </a>
        <a class="my-door__delete"
           @click.prevent="deleteModel(item.code)"
           href="#">x</a>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/page-title/page-title.vue'
import { API } from '@/constants/api'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      items: []
    }
  },
  computed: {
    ...mapGetters('config', [
      'code',
    ]),
    ...mapGetters('session', [
      'i18n',
    ]),
  },
  components: {
    PageTitle,
  },
  metaInfo() {
    return {
      title: this.i18n.MENU.my,
      meta: [
        {vmid: 'description', property: 'description', content: 'Мої двері'},
        {vmid: 'og:title', property: 'og:title', content: 'Мої двері'},
        {vmid: 'og:description', property: 'og:description', content: 'Мої двері'},
      ],
    }
  },
  methods: {
    getMyDoor(pic) {
      return require('@/assets/images/my-door/' + pic)
    },
    setModel(id) {
      this.$router.push({ query: { code: id }})
      location.reload()
    },
    async deleteModel(code) {
      await axios({
        method: 'delete',
        url: API.DELETE_DOOR,
        data: {
          code,
        },
      })
      await this.getDoors()
    },
    async getDoors() {
      let response;
      const {
        code
      } = this
      try {
        response = await axios.get(API.GET_DOORS, {
          params: {
            code,
          },
        })
      } catch (e) {
        console.error(e)
      }
      if (response?.data) {
        this.items = response.data
      }
    },
  },
  mounted() {
    this.getDoors()
  }
}
</script>

<style lang="scss">
.my-door {
  @include miw($xl) {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 20px 0;
  }
  &__link {
    display: block;
    color: #ffffff;
  }
  &__item {
    font-size: 13px;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
    margin: 0;
    background-color: rgba(#fff, .1);
    position: relative;
    cursor: pointer;
    @include miw($xl) {
      flex: 1 0 50%;
      max-width: 50%;
    }
  }

  &__img {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__delete {
    position: absolute;
    background: #C70552;
    width: 32px;
    height: 32px;
    right: 0;
    top: 0;
    text-decoration: none;
    font: 12px/100% 'FontAwesome5FreeNormal', sans-serif;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 9px;
    text-align: center;
  }
}

.model__empty {
  color: #ffffff;
}
</style>
